import React from 'react';
import { Trans } from 'react-i18next';
import { loginSchemaJSON } from './schema';
import classNames from 'classnames';
import { LoadingOutlined } from '@ant-design/icons';
import { MyInput } from '../../Base/Form';
import s from '../Login.module.scss';
const LoginMethod = ({ data, challenge, onSubmit, onChange, loading }) => {
    const { titleKey, fields } = loginSchemaJSON[challenge];
    const disabled = loading || fields.some(({ name }) => !data[name]);
    return (React.createElement("div", { className: s.loginMethodWrapper },
        React.createElement("h3", { className: s.loginMethodTitle },
            React.createElement(Trans, { i18nKey: titleKey })),
        React.createElement("form", { className: s.formWrapper, onSubmit: onSubmit },
            fields.map(({ name, type, label, placeholder, component, extraInputProps }, i) => {
                const Component = component ? MyInput[component] : MyInput;
                const params = Object.assign({ key: name, id: name, name,
                    type,
                    placeholder, value: data[name], onChangeHandler: (value) => onChange(name, value), className: s.input }, extraInputProps);
                return (React.createElement(React.Fragment, { key: name },
                    React.createElement("label", { className: s.authLabel, htmlFor: name },
                        React.createElement(Trans, { i18nKey: label })),
                    React.createElement(Component, Object.assign({}, params))));
            }),
            React.createElement("div", { className: s.submitWrapper },
                React.createElement("button", { type: "submit", disabled: disabled, className: classNames(s.submitSendPassword, {
                        [s.submitSendPasswordDisabled]: disabled,
                    }) }, loading ? React.createElement(LoadingOutlined, null) : React.createElement(Trans, { i18nKey: "login" }))))));
};
export default LoginMethod;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from "react";
import { AiFillPlayCircle } from "react-icons/ai";
import classnames from "classnames";
import { MESSAGE_DIRECTION } from "@whatsper/texterchat-common";
import { isSafari } from "../../../../helpers";
import { ReactComponent as PlaceholderSVG } from '../../../../assets/images/placeholders/video_placeholder_300.svg';
import { getFileUrl } from "../../../../store/FilesStore";
import PlayVideoModal from "./PlayVideoModal";
import MessageMarkdown from "../../../Base/Utils/MessageMarkdown/MessageMarkdown";
import s from "./VideoMessage.module.scss";
const VideoMessage = ({ message, shareFile, showCaption }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [url, setUrl] = useState(undefined);
    const videoRef = useRef(null);
    const { media: mediaArray, direction } = message;
    const media = mediaArray[0];
    useEffect(() => {
        getUrl().then((url) => {
            if (isSafari()) {
                // Safari fix to show first frame: https://stackoverflow.com/a/65226419
                setUrl(url + '#t=0.1');
            }
            else {
                setUrl(url);
            }
        });
    }, [media]);
    const getUrl = () => __awaiter(void 0, void 0, void 0, function* () {
        if (media.fileId) {
            if (isSafari() || window.ReactNativeWebView) {
                return shareFile(media.fileId);
            }
            return getFileUrl(media.fileId);
        }
        else if (media.url) {
            return media.url;
        }
        else {
            console.error('Message have no file ID or URL');
        }
    });
    const onLoadedData = () => {
        setLoading(false);
    };
    const onVideoClick = () => {
        setModalIsOpen(true);
    };
    return (React.createElement("div", { className: classnames(s["video-message-wrapper"], {
            [s["video-message-wrapper--loading"]]: loading,
        }) },
        React.createElement("div", null,
            React.createElement("video", Object.assign({ onClick: onVideoClick, onLoadedData: onLoadedData, src: url, ref: videoRef }, (isSafari() && { playsInline: true })),
                React.createElement("source", { src: url, type: media.contentType })),
            loading ? (React.createElement("div", { className: s.placeholderContainer },
                React.createElement(PlaceholderSVG, null))) : (React.createElement(AiFillPlayCircle, { className: s["play-button"] }))),
        (media.caption && showCaption) && (React.createElement("div", { className: classnames(s["video-message-caption"], {
                [s["video-message-caption--incoming"]]: direction === MESSAGE_DIRECTION.IN,
            }) },
            React.createElement(MessageMarkdown, { text: media.caption }))),
        React.createElement(PlayVideoModal, { visible: modalIsOpen, onClose: () => setModalIsOpen(false), url: url || '', type: media.contentType || '' })));
};
export default VideoMessage;

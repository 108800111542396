var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, observable, runInAction, computed, makeObservable, reaction } from "mobx";
import { API_PATH, CHAT_STATUS, FILTER_AGENTS, FILTER_DEPARTMENTS, LOCAL_STORAGE_KEYS } from "../../constants";
import { getAxios } from "../../backend";
import { CHAT_STATUSES_NAMES, EventName, User, } from "@whatsper/texterchat-common";
import { objectFill, strEnum, updateLocalStorage } from "../../helpers";
import { throttle } from "lodash";
import { LifecycleEvent } from "../../_events";
export const CHATS_GROUP = strEnum(["ALL", ...CHAT_STATUSES_NAMES]);
const loadChatsThrottle = 1000;
const loadCountThrottle = 1000;
const refreshThrottle = 1000;
/** Reload chats list and count interval in case if event stream not open */
const pollingIntervalMsec = 10000;
/** Count of chats to load at once */
const chatsPageItems = 50;
/** Static query params specific to groups */
const chatsGroupLoadQuery = {
    PENDING: {
        ownFirst: true,
    },
    ASSIGNED: {
        ownFirst: true,
    },
    BULK: {
        ownBulkFirst: true,
    },
};
class ChatsStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        /** Cancel previous load requests when this needed */
        Object.defineProperty(this, "chatsLoadAbortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "count", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "countLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "currentGroup", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: CHATS_GROUP.PENDING
        });
        Object.defineProperty(this, "chats", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: observable.array()
        });
        Object.defineProperty(this, "favoriteChats", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: observable.array()
        });
        Object.defineProperty(this, "chatsLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ChatsLoading.NONE
        });
        Object.defineProperty(this, "totalCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "searchFilter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                text: null,
                agent: localStorage.getItem(LOCAL_STORAGE_KEYS.FILTERS.CHATS_LIST_AGENTS_FILTER),
                department: localStorage.getItem(LOCAL_STORAGE_KEYS.FILTERS.CHATS_LIST_DEPARTMENTS_FILTER),
                channel: null,
            }
        });
        Object.defineProperty(this, "filtersJson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ""
        });
        /** Handle of polling interval, launched in case of events stream fail */
        Object.defineProperty(this, "pollingInterval", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        /** Last time chats was refreshed (timestamp) */
        Object.defineProperty(this, "lastRefreshedTime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        /**
         * Stop async routines.
         * Method should be called before object being destroyed or store setup again
         */
        Object.defineProperty(this, "destroy", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.stopPolling();
                if (null !== this.chatsLoadAbortController) {
                    this.chatsLoadAbortController.abort();
                    this.chatsLoadAbortController = null;
                }
            }
        });
        Object.defineProperty(this, "loadInitial", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                yield Promise.all([this.loadCount(), this.load()]);
            })
        });
        Object.defineProperty(this, "refresh", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: throttle((silent = true) => {
                this.loadCount(silent);
                this.load(false, silent);
            }, refreshThrottle)
        });
        /**
         * Load chats count
         *
         * @param silent Set to TRUE to not show loading
         */
        Object.defineProperty(this, "loadCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (silent = false) => __awaiter(this, void 0, void 0, function* () {
                try {
                    if (!silent) {
                        runInAction(() => (this.countLoading = true));
                    }
                    yield this.fetchCount().then((count) => {
                        runInAction(() => {
                            this.count = count;
                            this.countLoading = false;
                        });
                    });
                }
                catch (error) {
                    console.error("Error on chats count loading", error);
                    runInAction(() => (this.countLoading = false));
                }
            })
        });
        Object.defineProperty(this, "loadCountThrottled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: throttle(this.loadCount, loadCountThrottle, { trailing: true })
        });
        Object.defineProperty(this, "fetchCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (params = {}) => __awaiter(this, void 0, void 0, function* () {
                if (this.searchFilter.departmentConditionFilter) {
                    params.departmentConditionFilter = this.searchFilter.departmentConditionFilter;
                }
                return yield getAxios()
                    .then((axios) => {
                    return axios.get(`${API_PATH.CHATS}/count`, { params });
                })
                    .then(({ data }) => {
                    return data;
                });
            })
        });
        Object.defineProperty(this, "load", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (resetPagination = false, silent = false) => __awaiter(this, void 0, void 0, function* () {
                return this._load(false, resetPagination, silent);
            })
        });
        Object.defineProperty(this, "loadThrottled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: throttle(this.load, loadChatsThrottle, { trailing: true })
        });
        Object.defineProperty(this, "loadMore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (silent = false) => __awaiter(this, void 0, void 0, function* () {
                return this._load(true, false, silent);
            })
        });
        Object.defineProperty(this, "loadMoreThrottled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: throttle(this.loadMore, loadChatsThrottle, { trailing: true })
        });
        Object.defineProperty(this, "_load", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (more = false, resetPagination = false, silent = false) => __awaiter(this, void 0, void 0, function* () {
                try {
                    const textSearch = !!this.searchFilter.text;
                    let params = Object.assign({}, chatsGroupLoadQuery[this.currentGroup]);
                    if (more) {
                        if (this.totalCount !== null && this.chats.length >= this.totalCount) {
                            console.warn("Skipped chats loading more because all chats in group loaded already");
                            return;
                        }
                        params.skip = this.chats.length;
                        params.limit = chatsPageItems;
                    }
                    else {
                        params.limit = resetPagination || !this.chats.length ? chatsPageItems : this.chats.length;
                    }
                    if (this.currentGroup !== CHATS_GROUP.ALL && !textSearch) {
                        params.status = this.currentGroup;
                    }
                    if (this.searchFilter.text) {
                        params.search = this.searchFilter.text;
                    }
                    if (this.searchFilter.agent) {
                        params.agent = this.searchFilter.agent;
                    }
                    if (this.searchFilter.department) {
                        params.department = this.searchFilter.department;
                    }
                    if (this.searchFilter.channel) {
                        params.channelName = this.searchFilter.channel.name;
                        params.channelAccountId = this.searchFilter.channel.id;
                    }
                    if (this.searchFilter.departmentConditionFilter) {
                        params.departmentConditionFilter = this.searchFilter.departmentConditionFilter;
                    }
                    const filtersJson = JSON.stringify(this.searchFilter);
                    // Cancel previous request
                    if (null !== this.chatsLoadAbortController) {
                        this.chatsLoadAbortController.abort();
                        this.chatsLoadAbortController = null;
                    }
                    if (!silent) {
                        runInAction(() => {
                            this.chatsLoading = more ? ChatsLoading.MORE : ChatsLoading.ALL;
                        });
                    }
                    // Unfortunatelly we can't reuse instance each time,
                    // and instead need new instance every time,
                    // because otherwise it cancel request made after .abort() called
                    this.chatsLoadAbortController = new AbortController();
                    yield getAxios(false)
                        .then((axios) => {
                        var _a;
                        return axios.get(API_PATH.CHATS, {
                            params,
                            signal: (_a = this.chatsLoadAbortController) === null || _a === void 0 ? void 0 : _a.signal,
                        });
                    })
                        .then(({ data: result }) => {
                        runInAction(() => {
                            if (more) {
                                this.chats.push(...result.chats);
                            }
                            else {
                                this.chats.replace(result.chats);
                            }
                            this.totalCount = result.totalFound;
                            this.filtersJson = filtersJson;
                            this.lastRefreshedTime = Date.now();
                            this.chatsLoading = ChatsLoading.NONE;
                            if (textSearch) {
                                this._extendSearchToAllGroups(this.chats);
                            }
                        });
                    });
                }
                catch (error) {
                    // Do not log as an error, because this is expected behaviour.
                    // Also, do not change "loading" property, since request canceled
                    // only if new started
                    if (error.__CANCEL__) {
                        console.debug("Chats load canceled");
                    }
                    else {
                        console.error("Error on chats loading", error);
                        if (!silent) {
                            runInAction(() => (this.chatsLoading = ChatsLoading.NONE));
                        }
                    }
                }
            })
        });
        Object.defineProperty(this, "fetchChats", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (params) => __awaiter(this, void 0, void 0, function* () {
                return yield getAxios()
                    .then((axios) => {
                    return axios.get(API_PATH.CHATS, { params });
                })
                    .then(({ data }) => {
                    return data;
                });
            })
        });
        Object.defineProperty(this, "fetchMultipleChatsByIds", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (chatIds) => __awaiter(this, void 0, void 0, function* () {
                return yield getAxios()
                    .then((axios) => {
                    return axios.get(`${API_PATH.CHATS}/multiple`, {
                        params: { ids: chatIds }
                    });
                })
                    .then(({ data }) => {
                    return data;
                });
            })
        });
        Object.defineProperty(this, "fetchChatById", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (chatId) => __awaiter(this, void 0, void 0, function* () {
                return this.fetchChat(chatId, false);
            })
        });
        /**
         * Get chat by phone number. Returns chat is found, null otherwise
         *
         * @deprecated
         *
         * @param phone
         */
        Object.defineProperty(this, "fetchChatByPhone", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (phone) => __awaiter(this, void 0, void 0, function* () {
                try {
                    const chat = yield this.fetchChat(phone, true);
                    return chat;
                }
                catch (error) {
                    if (error.response && error.response.status === 404) {
                        return null;
                    }
                    throw error;
                }
            })
        });
        Object.defineProperty(this, "assignChat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (chatId, agentUid, departmentId) => __awaiter(this, void 0, void 0, function* () {
                const request = {
                    agentUid,
                    departmentId,
                };
                return getAxios()
                    .then((axios) => {
                    return axios.post(`${API_PATH.CHATS}/${chatId}/assign`, request);
                })
                    .then(({ data }) => {
                    this.rootStore.events.emit(EventName.chat_updated, data);
                    this.rootStore.activeChatStore.loadChatAccess(data);
                    return data;
                });
            })
        });
        Object.defineProperty(this, "patchChat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (chatId, update) => __awaiter(this, void 0, void 0, function* () {
                return getAxios()
                    .then((axios) => {
                    return axios.patch(`${API_PATH.CHATS}/${chatId}`, update);
                })
                    .then(({ data }) => {
                    this.rootStore.events.emit(EventName.chat_updated, data);
                    return data;
                });
            })
        });
        Object.defineProperty(this, "loadFavoriteChats", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                var _a;
                const { favorites } = this.rootStore.loginStore.userDetails || {};
                if (!favorites || favorites.length === 0)
                    return;
                const favoriteChatIds = (_a = favorites === null || favorites === void 0 ? void 0 : favorites.filter((chat) => chat.type === User.Favorites.Type.chat).map((chat) => chat.id)) !== null && _a !== void 0 ? _a : [];
                const chats = yield this.fetchMultipleChatsByIds(favoriteChatIds);
                this.favoriteChats.replace(chats);
            })
        });
        Object.defineProperty(this, "subscribeUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (chatId, subscribe) => __awaiter(this, void 0, void 0, function* () {
                return getAxios()
                    .then((axios) => {
                    return axios.post(`${API_PATH.CHATS}/${chatId}/subscribe`, { subscribe });
                })
                    .then(() => {
                    this.rootStore.activeChatStore.refresh();
                })
                    .catch((error) => {
                    console.error("Error on chat subscribe", error);
                });
            })
        });
        Object.defineProperty(this, "getUnsubscribedChats", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request) => __awaiter(this, void 0, void 0, function* () {
                return getAxios()
                    .then((axios) => {
                    return axios.post(`${API_PATH.CHATS}/unsubscribed`, Object.assign({}, request));
                })
                    .then(({ data }) => {
                    return data;
                })
                    .catch((error) => {
                    console.error("Error on fetching unsubscribed chats", error);
                });
            })
        });
        Object.defineProperty(this, "setSearchQuery", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (search) => {
                const changed = this.searchFilter.text !== search;
                this.searchFilter.text = search;
                if (changed) {
                    this.loadThrottled(true);
                }
            }
        });
        Object.defineProperty(this, "setDepartmentFilter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (department) => {
                if (department === FILTER_DEPARTMENTS.ALL_DEPARTMENTS) {
                    department = null;
                }
                let changed = department !== this.searchFilter.department;
                this.searchFilter.department = department;
                updateLocalStorage(LOCAL_STORAGE_KEYS.FILTERS.CHATS_LIST_DEPARTMENTS_FILTER, department);
                if (changed) {
                    // Unlike text, this and agent filters have no intermediate value
                    // so load can be loaded without throttle
                    this.load(true);
                }
            }
        });
        Object.defineProperty(this, "setAgentFilter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (agent) => {
                if (agent === FILTER_AGENTS.ALL_AGENTS) {
                    agent = null;
                }
                let changed = agent !== this.searchFilter.agent;
                this.searchFilter.agent = agent;
                updateLocalStorage(LOCAL_STORAGE_KEYS.FILTERS.CHATS_LIST_AGENTS_FILTER, agent);
                if (changed) {
                    this.load(true);
                }
            }
        });
        Object.defineProperty(this, "resetFilters", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { text, agent, department, channel } = this.searchFilter;
                const changed = text || agent || department || channel;
                this.searchFilter.text = null;
                this.searchFilter.agent = null;
                this.searchFilter.department = null;
                this.searchFilter.channel = null;
                if (changed) {
                    this.load(true);
                }
            }
        });
        Object.defineProperty(this, "setDepartmentConditionFilter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (departmentConditionFilter) => {
                let changed = departmentConditionFilter !== this.searchFilter.departmentConditionFilter;
                if (departmentConditionFilter) {
                    this.searchFilter.departmentConditionFilter = departmentConditionFilter;
                }
                else {
                    delete this.searchFilter.departmentConditionFilter;
                }
                if (changed) {
                    this.load(true);
                }
            }
        });
        Object.defineProperty(this, "getMediaFilesList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ chatId, mediaTypes, }) => __awaiter(this, void 0, void 0, function* () {
                const params = { mediaTypes };
                return getAxios()
                    .then((axios) => {
                    return axios.get(`${API_PATH.CHATS}/media/${chatId}/list`, { params });
                })
                    .then(({ data }) => {
                    return data;
                })
                    .catch((error) => {
                    console.error('Error while fetching media files media list', error);
                });
            })
        });
        Object.defineProperty(this, "toggleMarkFavorite", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (chatId, important) => __awaiter(this, void 0, void 0, function* () {
                if (important) {
                    yield this.rootStore.agentsStore.markFavorite([{
                            type: User.Favorites.Type.chat,
                            id: chatId
                        }]);
                }
                else {
                    yield this.rootStore.agentsStore.unmarkFavorite(chatId, User.Favorites.Type.chat);
                }
            })
        });
        Object.defineProperty(this, "isChatImportant", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (chatId) => {
                var _a;
                const favorites = ((_a = this.rootStore.loginStore.userDetails) === null || _a === void 0 ? void 0 : _a.favorites) || [];
                return favorites
                    .filter((favorite) => favorite.type === User.Favorites.Type.chat)
                    .some((favorite) => favorite.id === chatId);
            }
        });
        Object.defineProperty(this, "setChannel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (channel, initSearch = true) => {
                const changed = channel && !this.searchFilter.channel
                    || !channel && this.searchFilter.channel
                    || channel
                        && this.searchFilter.channel
                        && !(channel.name === this.searchFilter.channel.name && channel.id === this.searchFilter.channel.id);
                this.searchFilter.channel = channel;
                if (changed && initSearch) {
                    this.load(true);
                }
            }
        });
        Object.defineProperty(this, "_extendSearchToAllGroups", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (chats) => {
                const chatsWithGroup = chats.filter((chat) => CHAT_STATUS[chat.status] === this.currentGroup);
                if (chatsWithGroup.length > 0) {
                    this.chats.replace(chatsWithGroup);
                    this.totalCount = chatsWithGroup.length;
                }
                else {
                    this.setCurrentGroup(CHATS_GROUP.ALL);
                }
            }
        });
        makeObservable(this);
        this.rootStore = rootStore;
        this.count = {
            all: 0,
            waiting: 0,
            status: objectFill(CHAT_STATUSES_NAMES, 0),
        };
        this.rootStore.events
            .addListener(EventName.chat_created, (data) => this.onChatCreatedEvent(data))
            .addListener(EventName.chat_updated, (data) => this.onChatUpdatedEvent(data))
            .addListener(EventName.chat_deleted, (data) => this.onChatDeletedEvent(data))
            .addListener(LifecycleEvent.STREAM_OPEN, () => this.stopPolling())
            .addListener(LifecycleEvent.STREAM_FAILED, () => this.startPolling());
        /** Load favorite chats after user logs in and listen for favorites change */
        reaction(() => { var _a, _b; return (_b = (_a = this.rootStore.loginStore) === null || _a === void 0 ? void 0 : _a.userDetails) === null || _b === void 0 ? void 0 : _b.favorites; }, (favorites) => {
            if (favorites) {
                this.loadFavoriteChats();
            }
        });
    }
    get currentGroupStatus() {
        switch (this.currentGroup) {
            case CHATS_GROUP.PENDING:
                return CHAT_STATUS.PENDING;
            case CHATS_GROUP.ASSIGNED:
                return CHAT_STATUS.ASSIGNED;
            case CHATS_GROUP.BULK:
                return CHAT_STATUS.BULK;
            case CHATS_GROUP.RESOLVED:
                return CHAT_STATUS.RESOLVED;
            case CHATS_GROUP.BOT:
                return CHAT_STATUS.BOT;
            case CHATS_GROUP.ALL:
                return undefined;
            default:
                return undefined;
        }
    }
    get chatsHasMore() {
        return this.totalCount ? this.totalCount > this.chats.length : false;
    }
    get channel() {
        return this.searchFilter.channel;
    }
    get hasFilterApplied() {
        return !!(this.searchFilter.text || this.searchFilter.agent || this.searchFilter.department || this.searchFilter.channel);
    }
    setCurrentGroup(groupName) {
        const changed = groupName !== this.currentGroup;
        this.currentGroup = groupName;
        if (changed) {
            this.setSearchQuery("");
        }
        if (changed || typeof this.totalCount !== "number") {
            this.load(true);
        }
        else {
            const filtersJson = JSON.stringify(this.searchFilter);
            if (filtersJson !== this.filtersJson) {
                this.load(true);
            }
        }
    }
    /**
     * Get chat by channel information. Returns chat if it found, NULL otherwise
     *
     * @param param0 Channel info
     */
    fetchChatByChannelId({ name, accountId, id }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const chat = yield getAxios(false)
                    .then((axios) => {
                    return axios.get(`${API_PATH.CHATS}/channel/${name}/${accountId}/${id}`);
                })
                    .then(({ data }) => {
                    return data;
                });
                return chat;
            }
            catch (error) {
                if (error.response && error.response.status === 404) {
                    return null;
                }
                throw error;
            }
        });
    }
    fetchChat(criteria, isPhone) {
        return __awaiter(this, void 0, void 0, function* () {
            return getAxios(!isPhone)
                .then((axios) => {
                return axios.get(isPhone ? `${API_PATH.CHATS}/phone/${criteria.replace(/[^\d]/g, "")}` : `${API_PATH.CHATS}/${criteria}`);
            })
                .then(({ data }) => {
                return data;
            });
        });
    }
    resolveChat(chatId, notifyClient, runBot, nodeName) {
        return __awaiter(this, void 0, void 0, function* () {
            let request = {
                notifyClient,
            };
            if (runBot) {
                request = Object.assign(Object.assign({}, request), { runBot: {
                        nodeName,
                    } });
            }
            yield getAxios().then((axios) => {
                return axios.post(`${API_PATH.CHATS}/${chatId}/resolve`, request);
            });
        });
    }
    /**
     * Create new chat
     *
     * @param channelContactId Provider/channel ID of chat contact
     * @param channel Messaging channel. If not provided used default one
     * @param accountId Messaging channel account ID. If not provided used default one
     *
     * @throws Error If channel infoo not provided and found no default channel
     */
    createChatByChannelId(channelContactId, channel, accountId) {
        return __awaiter(this, void 0, void 0, function* () {
            const defaultAccount = this.rootStore.channels.defaultAccount;
            if (!defaultAccount) {
                throw new Error('No default channel account found!');
            }
            const request = {
                channel: channel || defaultAccount.channel,
                accountId: accountId || defaultAccount.accountId,
                channelContactId,
            };
            return getAxios()
                .then((axios) => {
                return axios.post(API_PATH.CHATS, request);
            })
                .then(({ data }) => {
                return data;
            });
        });
    }
    bulkResolve(statusToClear, hours) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = {
                beforeTimestamp: Date.now() - hours * (60 * 60 * 1000),
                status: statusToClear,
            };
            return getAxios().then((axios) => {
                return axios.post(`${API_PATH.CHATS}/bulk-resolve`, params);
            });
        });
    }
    /**
     * Set read specified IDs or all messages of the chat
     *
     * @param chatId Parent chat ID
     * @param messages Messages IDs or boolean TRUE to set read all chat messages
     */
    setMessagesRead(chatId, messages) {
        return __awaiter(this, void 0, void 0, function* () {
            let params;
            if (typeof messages === "object") {
                params = { messages };
            }
            else {
                params = { all: true };
            }
            return getAxios().then((axios) => {
                return axios.post(`${API_PATH.CHATS}/${chatId}/set-read`, params);
            });
        });
    }
    /** @todo Verify it working */
    getBotSummaryData(id) {
        return __awaiter(this, void 0, void 0, function* () {
            return getAxios()
                .then((axios) => {
                return axios.get(`${API_PATH.BOTS}/session/${id}/summary`);
            })
                .then(({ data }) => {
                return data;
            });
        });
    }
    onChatCreatedEvent(chat) {
        this.onEventReceived();
        this.loadCountThrottled(true);
        const groupName = CHAT_STATUS[chat.status];
        if (groupName === this.currentGroup || CHATS_GROUP.ALL === this.currentGroup) {
            this.upsertChat(chat, true);
            // Increase total count of chats
            if (this.totalCount) {
                this.totalCount += 1;
            }
        }
    }
    onChatUpdatedEvent(chatNew) {
        this.onEventReceived();
        this.loadCountThrottled(true);
        // Is chat already present in current loaded list with same status and department?
        const chatPrev = this.chats.find(({ _id, status, departmentId }) => {
            return _id === chatNew._id && status === chatNew.status && departmentId === chatNew.departmentId;
        });
        // Status not changed, so replace old with new one
        // and move to top but considering sorting
        if (chatPrev) {
            // Just update in-place if no messages was received/sent, mnot changed agent and not changed importancy
            if (chatNew.last_message_timestamp === chatPrev.last_message_timestamp &&
                chatNew.agent.uid === chatPrev.agent.uid &&
                chatNew.important === chatPrev.important) {
                runInAction(() => {
                    this.chats.replace(this.chats.map((chat) => {
                        return chat._id === chatNew._id ? chatNew : chat;
                    }));
                });
            }
            else {
                // Calculate and insert into a new position
                this.upsertChat(chatNew, false);
            }
        }
        else {
            // Need load list again because unclear where chat was previously
            this.loadThrottled(false, true);
        }
    }
    /**
     * Insert new/updated chat considering special sorting cases
     *
     * @param chat Chat to insert/update
     * @param isNew Is new chat
     */
    upsertChat(chat, isNew) {
        let position = 0;
        const query = chatsGroupLoadQuery[this.currentGroup] || {}, isOwnFirst = query.ownFirst === true, isOwnBulkFirst = query.ownBulkFirst === true, { _id } = this.rootStore.loginStore.userDetails || {}, own = chat.agent.uid === _id, ownBulk = chat.sendingBulkAgent === _id;
        const chats = isNew
            ? this.chats
            : this.chats.filter(({ _id }) => {
                return _id !== chat._id;
            });
        for (let i = 0; i < chats.length; i++) {
            // It can be single if() but this way it more readable
            if (isOwnFirst && !own && chats[i].agent.uid === _id) {
                continue;
            }
            if (isOwnBulkFirst && !ownBulk && chats[i].sendingBulkAgent === _id) {
                continue;
            }
            if (chats[i].important && !chat.important) {
                continue;
            }
            position = i;
            break;
        }
        this.chats.replace([...chats.slice(0, position), chat, ...chats.slice(position)]);
    }
    onChatDeletedEvent({ _id }) {
        this.onEventReceived();
        this.loadCountThrottled(true);
        if (typeof this.totalCount === "number") {
            runInAction(() => {
                let found = false;
                this.chats.replace(this.chats.filter((chat) => {
                    if (_id === chat._id) {
                        found = true;
                        return false;
                    }
                    return true;
                }));
                if (found) {
                    if (this.totalCount) {
                        this.totalCount--;
                    }
                }
            });
        }
    }
    onEventReceived() {
        this.lastRefreshedTime = Date.now();
        this.stopPolling();
    }
    startPolling() {
        if (null === this.pollingInterval) {
            // Run at begin too
            this.refresh();
            this.pollingInterval = setInterval(() => {
                if (Date.now() - this.lastRefreshedTime >= pollingIntervalMsec) {
                    this.refresh();
                }
            }, pollingIntervalMsec);
        }
    }
    stopPolling() {
        if (null !== this.pollingInterval) {
            clearInterval(this.pollingInterval);
            this.pollingInterval = null;
            // Make sure all pulled for period between latest polling refresh and polling stopped
            this.refresh();
        }
    }
}
__decorate([
    observable
], ChatsStore.prototype, "count", void 0);
__decorate([
    observable
], ChatsStore.prototype, "countLoading", void 0);
__decorate([
    observable
], ChatsStore.prototype, "currentGroup", void 0);
__decorate([
    observable
], ChatsStore.prototype, "chatsLoading", void 0);
__decorate([
    observable
], ChatsStore.prototype, "totalCount", void 0);
__decorate([
    observable
], ChatsStore.prototype, "searchFilter", void 0);
__decorate([
    computed
], ChatsStore.prototype, "currentGroupStatus", null);
__decorate([
    computed
], ChatsStore.prototype, "chatsHasMore", null);
__decorate([
    computed
], ChatsStore.prototype, "channel", null);
__decorate([
    computed
], ChatsStore.prototype, "hasFilterApplied", null);
__decorate([
    action
], ChatsStore.prototype, "setCurrentGroup", null);
__decorate([
    action
], ChatsStore.prototype, "assignChat", void 0);
__decorate([
    action
], ChatsStore.prototype, "resolveChat", null);
__decorate([
    action
], ChatsStore.prototype, "patchChat", void 0);
__decorate([
    action
], ChatsStore.prototype, "loadFavoriteChats", void 0);
__decorate([
    action
], ChatsStore.prototype, "subscribeUser", void 0);
__decorate([
    action
], ChatsStore.prototype, "getUnsubscribedChats", void 0);
__decorate([
    action
], ChatsStore.prototype, "setSearchQuery", void 0);
__decorate([
    action
], ChatsStore.prototype, "setDepartmentFilter", void 0);
__decorate([
    action
], ChatsStore.prototype, "setAgentFilter", void 0);
__decorate([
    action
], ChatsStore.prototype, "resetFilters", void 0);
__decorate([
    action
], ChatsStore.prototype, "setDepartmentConditionFilter", void 0);
__decorate([
    action
], ChatsStore.prototype, "getMediaFilesList", void 0);
__decorate([
    action
], ChatsStore.prototype, "bulkResolve", null);
__decorate([
    action
], ChatsStore.prototype, "onChatCreatedEvent", null);
__decorate([
    action
], ChatsStore.prototype, "upsertChat", null);
__decorate([
    action
], ChatsStore.prototype, "setChannel", void 0);
export var ChatsLoading;
(function (ChatsLoading) {
    ChatsLoading["ALL"] = "ALL";
    ChatsLoading["MORE"] = "MORE";
    ChatsLoading["NONE"] = "NONE";
})(ChatsLoading || (ChatsLoading = {}));
export default ChatsStore;

import { Auth } from '@whatsper/texterchat-common';
export const getLoginMethodFromChallenge = (challenge) => {
    switch (challenge) {
        case 'none':
        case 'update_password':
            return Auth.Methods.Method.password;
        case 'mfa':
            return Auth.Methods.Method.generic_otp;
        default:
            return Auth.Methods.Method.password;
    }
};

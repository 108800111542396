import React from 'react';
import { ChatsLoading } from '../../../store/ChatsStore';
import List from './List';
import { Trans } from 'react-i18next';
import styles from './ChatsList.module.scss';
const FavoriteChats = ({ chats, status, onClickChat }) => {
    const favoriteChats = typeof status === 'undefined'
        ? chats
        : chats.filter((chat) => chat.status === status);
    if (favoriteChats.length === 0)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement("h2", { key: "chats-header", className: styles.resultsListSearchSectionTitle },
            React.createElement(Trans, { i18nKey: "favorites" })),
        React.createElement(List, { id: "favorite-chats", chats: favoriteChats, onClickChat: onClickChat, loading: ChatsLoading.NONE, hasMore: false })));
};
export default FavoriteChats;

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import classNames from 'classnames';
import { LoadingOutlined } from '@ant-design/icons';
import { Input as AntdInput } from 'antd';
import { getDirection } from '../../../../helpers';
import './MyInput.scss';
const Input = (_a) => {
    var { className, size = 'middle', fullWidth = true, bordered = true, onChangeHandler, loading, allowClear, forwardRef } = _a, rest = __rest(_a, ["className", "size", "fullWidth", "bordered", "onChangeHandler", "loading", "allowClear", "forwardRef"]);
    return (React.createElement(AntdInput, Object.assign({ className: classNames('myinput-wrapper', className, {
            rtl: getDirection() === 'rtl',
            'full-width': fullWidth,
            large: size === 'large',
            middle: size === 'middle',
        }), dir: getDirection(), ref: forwardRef, size: size, onChange: (e) => onChangeHandler === null || onChangeHandler === void 0 ? void 0 : onChangeHandler(e.target.value), suffix: loading ? React.createElement(LoadingOutlined, { className: 'myinput-loading' }) : null, allowClear: loading ? false : allowClear, bordered: bordered }, rest)));
};
const TextArea = (_a) => {
    var { className, fullWidth, size, forwardRef, onChange, bordered = false } = _a, rest = __rest(_a, ["className", "fullWidth", "size", "forwardRef", "onChange", "bordered"]);
    return (React.createElement(AntdInput.TextArea, Object.assign({ className: classNames('myinput-textarea-wrapper', className, {
            rtl: getDirection() === 'rtl',
            'full-width': fullWidth,
        }), dir: getDirection(), ref: forwardRef, size: size, bordered: bordered, onChange: (e) => onChange === null || onChange === void 0 ? void 0 : onChange(e.target.value, e.target.selectionStart, e.target.selectionEnd) }, rest)));
};
const Password = (_a) => {
    var { className, size, forwardRef, onChangeHandler } = _a, rest = __rest(_a, ["className", "size", "forwardRef", "onChangeHandler"]);
    return (React.createElement(AntdInput.Password, Object.assign({ className: classNames('myinput-wrapper', className, {
            rtl: getDirection() === 'rtl',
            large: size === 'large',
            middle: size === 'middle',
        }), onChange: (e) => onChangeHandler === null || onChangeHandler === void 0 ? void 0 : onChangeHandler(e.target.value), dir: getDirection(), ref: forwardRef, size: size }, rest)));
};
export default Object.assign(Input, { TextArea, Password, Text: AntdInput });

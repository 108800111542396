import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { LOCAL_STORAGE_KEYS, TEMPLATE_SORT, TEMPLATE_USAGE } from '../../../constants';
import List from '../List';
import { ReactComponent as QuickRepliesCross } from '../../../assets/messageStatuses/closeCross.svg';
import { ReactComponent as QuickRepliesGear } from '../../../assets/icons/leftMenuIcons/settingsOutlined.svg';
import ActiveIcon from '../../Chat/ActiveIcon';
import { filterByName, filterTemplates, sortByDateCreated, sortByName, sortByLastUsed } from './helpers';
import useCheckIfMobile from '../../../helpers/hooks/useCheckIfMobile';
import { getDirection, updateLocalStorage } from '../../../helpers';
import Filters from '../Filters';
import { useStore } from '../../../store/rootStore';
import styles from './ListFilterable.module.scss';
const TemplatesListFilterable = ({ templates, onClicked, loading, onlyUsage, onlyStatus, onClose, noHeader, }) => {
    var _a;
    const [department, setDepartment] = useState(localStorage.getItem(LOCAL_STORAGE_KEYS.FILTERS.SINGLE_TEMPLATE_DEPARTMENTS_FILTER) || null);
    const [sort, setSort] = useState(TEMPLATE_SORT.LAST_USED);
    const [searchValue, setSearchValue] = useState('');
    const isLessThanDesktop = useCheckIfMobile(1600);
    const { templateMessagesStore } = useStore();
    const { i18n } = useTranslation();
    const sorterMap = {
        [TEMPLATE_SORT.NAME]: sortByName,
        [TEMPLATE_SORT.DATE_CREATED]: sortByDateCreated,
        [TEMPLATE_SORT.LAST_USED]: sortByLastUsed(templateMessagesStore.templateMessagesLists, ((_a = templateMessagesStore.selectedAccountId) === null || _a === void 0 ? void 0 : _a.id) || ''),
    };
    const onDepartmentChanged = (value) => {
        setDepartment(value);
        updateLocalStorage(LOCAL_STORAGE_KEYS.FILTERS.SINGLE_TEMPLATE_DEPARTMENTS_FILTER, value);
    };
    const filteredTemplates = () => {
        return templates
            .filter(filterTemplates({ onlyUsage, onlyStatus, department, showNew: false }))
            .filter(filterByName(searchValue))
            .sort(sorterMap[sort]);
    };
    return (React.createElement("div", { className: styles.container },
        !noHeader && (React.createElement("div", { className: styles.header },
            React.createElement("div", { className: styles.title },
                React.createElement(ActiveIcon, { component: QuickRepliesGear, additionalStyles: styles.headerIcon }),
                React.createElement("div", null,
                    React.createElement(Trans, { i18nKey: "templateMessages" }))),
            React.createElement("div", { className: styles.closeModal },
                React.createElement(ActiveIcon, { component: QuickRepliesCross, action: onClose })))),
        React.createElement(Filters, { department: department, onDepartmentChanged: onDepartmentChanged, sort: sort, onSortChanged: setSort, searchValue: searchValue, onSearchChanged: setSearchValue, disabled: loading, isLessThanDesktop: isLessThanDesktop, i18n: i18n }),
        React.createElement("div", { className: styles.listContainer },
            React.createElement(List, { templates: filteredTemplates(), onClicked: onClicked, loading: loading, isInbox: onlyUsage === TEMPLATE_USAGE.INBOX, isRTL: getDirection() === 'rtl' }))));
};
export default observer(TemplatesListFilterable);

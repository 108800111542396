var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, computed, observable, runInAction, when, toJS, makeObservable, reaction, } from 'mobx';
import parsePhoneNumber, { formatNumber } from 'libphonenumber-js';
import { CHATS_GROUP } from '../ChatsStore';
import { MessagesSearchLoading } from '../MessagesStore';
import { API_PATH, MEDIA_TYPE, MESSAGE_STATUS } from '../../constants';
import { EventName, isRegularMessage, MESSAGE_DIRECTION, MessagingChannel, isMediaMessage, Messenger, ChatAccessType } from '@whatsper/texterchat-common';
import { LifecycleEvent } from '../../_events';
import { debounce, isArray, throttle } from 'lodash';
import { setCSSVariable, shadeColor } from '../../helpers';
import { getAxios } from '../../backend';
import { RightSidebarModules } from '../NavigationStore';
const day = 24 * 60 * 60 * 1000;
const week = 7 * day;
/** Count of messages to load at once */
export const messagesPageItems = 50;
/**
 * Do not load more than this amount of messages - remove all above this amount
 * from other side of list when loading new portion
 */
export const messagesLoadMax = 250;
/** Count of local cached chats with messages */
export const cachedChatsSize = 20;
/** Reload active chat messages interval in case if event stream not open */
const pollingIntervalMsec = 10000;
const refreshThrottle = 1000;
class ActiveChatStore {
    get inChatSearchHasMore() {
        var _a, _b;
        return ((_a = this.inChatSearchResults) === null || _a === void 0 ? void 0 : _a.totalHits) > ((_b = this.inChatSearchResults) === null || _b === void 0 ? void 0 : _b.messages.length);
    }
    get indexedImageMessages() {
        return this.messages
            .filter(isMediaMessage)
            .map((m) => m.media)
            .flat()
            .filter((m) => m.mediaType === MEDIA_TYPE.IMAGE)
            .map((m, index) => (Object.assign(Object.assign({}, m), { index })));
    }
    get defaultTemplate() {
        var _a, _b;
        const accountId = (_a = this.chat) === null || _a === void 0 ? void 0 : _a.channelInfo.accountId;
        if (accountId) {
            const template = (_b = this.rootStore.templateMessagesStore.templateMessagesLists[accountId]) === null || _b === void 0 ? void 0 : _b.find((tmp) => tmp.isDefault);
            if (template) {
                return template;
            }
            return null;
        }
        return null;
    }
    get linkedChatsWithoutActiveChat() {
        if (this.linkedChats) {
            return this.linkedChats
                .filter(lc => { var _a; return lc._id !== ((_a = this.chat) === null || _a === void 0 ? void 0 : _a._id); })
                .sort((a, b) => a.channelInfo.name > b.channelInfo.name ? -1 : 1);
        }
        else {
            return [];
        }
    }
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "chat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "chatAccess", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "linkedChats", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: observable([])
        });
        /**
         * Curent chat messages
         */
        Object.defineProperty(this, "messages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: observable.array()
        });
        Object.defineProperty(this, "messagesLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: MessagesLoading.NONE
        });
        /** Current chat minimal message received time (first message time). FALSE if not exists (no messages) */
        Object.defineProperty(this, "messagesMinTime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        /** Current chat maximal message received time (last message time). FALSE if not exists (no messages) */
        Object.defineProperty(this, "messagesMaxTime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "chatLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "linkedChatsLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "messageId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "cache", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: observable.array()
        });
        Object.defineProperty(this, "showTemplateMessages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "blockChatLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "blockUserError", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "filesData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "filePreview", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "filePreviewLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "theme", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(this, "activeReplyMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "important", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "sendLocationCoords", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "inChatSearchLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: MessagesSearchLoading.NONE
        });
        Object.defineProperty(this, "inChatSearchQuery", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "inChatSearchResults", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: { messages: [], totalHits: 0 }
        });
        Object.defineProperty(this, "isInChatSearchOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "pollingInterval", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        /** Last time active chat messages was refreshed (timestamp) */
        Object.defineProperty(this, "lastMessagesRefreshedTime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        /**
         * Load previous or next messages to those loaded already
         *
         * @param dir Previous or next messages
         */
        Object.defineProperty(this, "loadMoreMessages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (dir) => __awaiter(this, void 0, void 0, function* () {
                var _a;
                if (!this.chat || !this.messages.length || this.messagesLoading !== MessagesLoading.NONE) {
                    return;
                }
                if (!((_a = this.chatAccess) === null || _a === void 0 ? void 0 : _a.view)) {
                    return;
                }
                const curChatId = toJS(this.chat._id);
                const { _id, received } = this.messages[MessagesLoading.PREV === dir ? 0 : this.messages.length - 1];
                runInAction(() => this.messagesLoading = dir);
                try {
                    const { messages, minTime, maxTime } = yield this.rootStore.messages.fetchMessages(this.chat._id, {
                        [MessagesLoading.PREV === dir ? 'beforeTime' : 'afterTime']: `${received}`,
                        [MessagesLoading.PREV === dir ? 'beforeId' : 'afterId']: _id,
                    }, messagesPageItems);
                    // Make sure chat was not switched during this load
                    if (this.chat._id === curChatId) {
                        runInAction(() => {
                            this.messagesMinTime = minTime;
                            this.messagesMaxTime = maxTime;
                            // Make sure not add duplicates,
                            // because it can happen with pagination by time,
                            // when more than one received same time
                            const idsLoaded = this.messages.map(({ _id }) => _id), addMessages = messages.filter(({ _id }) => {
                                return !idsLoaded.includes(_id);
                            }), 
                            // Do not load more than max allowed
                            stripCount = this.messages.length + addMessages.length - messagesLoadMax;
                            if (MessagesLoading.PREV === dir) {
                                this.messages.replace([
                                    ...addMessages,
                                    ...(stripCount > 0
                                        ? this.messages.slice(0, this.messages.length - stripCount)
                                        : this.messages),
                                ]);
                            }
                            else {
                                this.messages.replace([
                                    ...(stripCount > 0
                                        ? this.messages.slice(stripCount)
                                        : this.messages),
                                    ...addMessages,
                                ]);
                            }
                            this.messagesLoading = MessagesLoading.NONE;
                            this.lastMessagesRefreshedTime = Date.now();
                        });
                    }
                }
                catch (error) {
                    console.error(error);
                    runInAction(() => this.messagesLoading = MessagesLoading.NONE);
                }
            })
        });
        Object.defineProperty(this, "refresh", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: throttle((silent = true) => {
                this.loadMessages(true, silent);
            }, refreshThrottle)
        });
        /**
         * Load messages initially or reload exists list
         *
         * @param resetPagination Load latest messages instead of keeping exists pagination. Ignored if "withId param provided"
         * @param silent Do not show loading
         * @param withId Load starting form this message ID. This resets pagination regardless of "resetPagination" param value
         */
        Object.defineProperty(this, "loadMessages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (resetPagination = false, silent = false, withId) => __awaiter(this, void 0, void 0, function* () {
                var _b;
                if (!this.chat || this.messagesLoading !== MessagesLoading.NONE) {
                    return;
                }
                if (!((_b = this.chatAccess) === null || _b === void 0 ? void 0 : _b.view)) {
                    return;
                }
                const curChatId = toJS(this.chat._id);
                try {
                    let limit = messagesPageItems;
                    const query = Object.assign({ withId }, withId && { prependCount: 25 });
                    // Keep exists loaded range
                    if (!resetPagination && !withId && this.messages.length) {
                        // @ts-expect-error
                        query.withId = this.messages[0]._id;
                        limit = this.messages.length;
                    }
                    if (!silent) {
                        runInAction(() => this.messagesLoading = MessagesLoading.ALL);
                    }
                    const { messages, minTime, maxTime } = yield this.rootStore.messages.fetchMessages(this.chat._id, query, limit);
                    // Make sure chat was not switched during this load
                    if (this.chat._id === curChatId) {
                        runInAction(() => {
                            this.messagesMinTime = minTime;
                            this.messagesMaxTime = maxTime;
                            this.messages.replace(messages);
                            this.messagesLoading = MessagesLoading.NONE;
                            this.lastMessagesRefreshedTime = Date.now();
                        });
                    }
                }
                catch (error) {
                    console.error(error);
                    runInAction(() => this.messagesLoading = MessagesLoading.NONE);
                }
            })
        });
        /**
         *
         * @param chatId
         * @param messageId Current message ID to load it and next
         * @param forceLoad Load anyway, regardles of that present in cache or not
         */
        Object.defineProperty(this, "setActiveChatId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (chatId = null, messageId, forceLoad = false) => __awaiter(this, void 0, void 0, function* () {
                if (chatId) {
                    if (this.chat && this.chat._id !== chatId) {
                        this.removeActiveChat();
                    }
                    const { chatsStore } = this.rootStore;
                    chatsStore.setSearchQuery(null);
                    // Get from cache only if message ID not specified
                    // because it can be absent in cache
                    if (!forceLoad && !messageId) {
                        const cached = this.getCached(chatId);
                        if (cached) {
                            yield this.setActiveChat(cached.chat);
                            return;
                        }
                    }
                    runInAction(() => this.chatLoading = true);
                    try {
                        const chat = yield chatsStore.fetchChatById(chatId);
                        yield this.setActiveChat(chat, messageId, !!messageId ? true : undefined);
                        const { name, accountId, id } = chat.channelInfo;
                        const url = `/contact/${name}/${accountId}/${id}`;
                        /* update url if its not correct */
                        if (window.location.pathname !== url) {
                            history.replaceState({}, '', url);
                        }
                    }
                    catch (error) {
                        console.error(error);
                    }
                    finally {
                        runInAction(() => this.chatLoading = false);
                    }
                }
                else {
                    this.removeActiveChat();
                }
            })
        });
        /**
         * Load exists chat for this phone number or create new
         *
         * @param id Provider/channel ID of the contact
         * @param channel Messaging channel
         * @param channelAccountId Messaging channel account id
         */
        Object.defineProperty(this, "setActiveChatByChannelId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (id, channel, channelAccountId, forceLoad = false) => __awaiter(this, void 0, void 0, function* () {
                if (!channel || !channelAccountId) {
                    const defaultAccount = this.rootStore.channels.defaultAccount;
                    if (!defaultAccount) {
                        throw new Error('Channel info not provided, but no default channel account found!');
                    }
                    channel = defaultAccount.channel;
                    channelAccountId = defaultAccount.accountId;
                }
                if (this.chat
                    && !(
                    // Different chat for sure
                    this.chat.channelInfo.id === id
                        && this.chat.channelInfo.name === channel
                        && this.chat.channelInfo.accountId === channelAccountId)) {
                    this.removeActiveChat();
                }
                const { chatsStore } = this.rootStore;
                chatsStore.setSearchQuery(null);
                try {
                    if (!forceLoad) {
                        const cached = this.getCachedByChannelId(id, channel, channelAccountId);
                        if (cached) {
                            yield this.setActiveChat(cached.chat);
                            return;
                        }
                    }
                    runInAction(() => this.chatLoading = true);
                    const chat = yield chatsStore.fetchChatByChannelId({ id, name: channel, accountId: channelAccountId });
                    if (chat) {
                        chatsStore.setCurrentGroup(CHATS_GROUP.ALL);
                        yield this.setActiveChat(chat);
                    }
                    else {
                        // Not found on server - creating new chat
                        const chat = yield chatsStore.createChatByChannelId(id, channel, channelAccountId);
                        yield this.setActiveChat(chat, undefined, false);
                    }
                }
                catch (error) {
                    console.error(error);
                }
                finally {
                    runInAction(() => this.chatLoading = false);
                }
            })
        });
        Object.defineProperty(this, "removeActiveChat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.chat) {
                    this.cacheCurrent();
                    this.chat = null;
                    this.messages.clear();
                    this.messageId = null;
                    this.messagesMinTime = null;
                    this.messagesMaxTime = null;
                    this.messagesLoading = MessagesLoading.NONE;
                }
            }
        });
        Object.defineProperty(this, "setActiveChat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (chat, messageId, fetchMessages) => __awaiter(this, void 0, void 0, function* () {
                var _c;
                if (((_c = this.chat) === null || _c === void 0 ? void 0 : _c._id) !== chat._id) {
                    this.removeActiveChat();
                    // get chat access before fetching chat messages
                    yield this.loadChatAccess(chat);
                }
                try {
                    // Do we set chat other than was
                    const switchChat = !this.chat || this.chat._id !== chat._id;
                    // Do we setting other message
                    const loadNewMessage = messageId && this.messageId !== messageId;
                    if (switchChat) {
                        this.cacheCurrent();
                        this.rootStore.crmStore.getCustomerDetails(chat._id);
                        this.rootStore.chatNotesStore.getChatNotes(chat._id);
                    }
                    runInAction(() => {
                        if (switchChat) {
                            // Use cached messages only if new message not requested
                            const cached = !loadNewMessage ? this.getCached(chat._id) : null;
                            if (cached) {
                                this.messagesMinTime = cached.minTime;
                                this.messagesMaxTime = cached.maxTime;
                                this.messages.replace(cached.messages);
                            }
                            else {
                                this.messagesMinTime = null;
                                this.messagesMaxTime = null;
                                this.messages.clear();
                            }
                            this.messagesLoading = MessagesLoading.NONE;
                        }
                        // Set requested message id, or if none then reset previous only if chat switch
                        this.messageId = messageId || (switchChat ? null : this.messageId);
                        this.blockUserError = false;
                        this.chat = chat;
                        this.setTheme(chat);
                    });
                    // Load messages if directly requested to load,
                    // or new message requested, or we have no messages limit IDs,
                    // and load not directly set to false
                    if (fetchMessages || loadNewMessage || (this.messagesMinTime === null && fetchMessages !== false)) {
                        yield this.loadMessages(true, false, messageId);
                    }
                }
                catch (error) {
                    console.error(error);
                }
            })
        });
        Object.defineProperty(this, "setMessagesRead", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (messages) => __awaiter(this, void 0, void 0, function* () {
                var _d;
                if (!this.chat || this.chat.agent.uid !== ((_d = this.rootStore.loginStore.userDetails) === null || _d === void 0 ? void 0 : _d._id) || this.chat.unreadCount < 1) {
                    return;
                }
                if (typeof messages === 'object') {
                    messages = messages.filter((message) => {
                        var _a;
                        if (isRegularMessage(message)) {
                            const { direction, status, parent_chat } = message;
                            return direction === MESSAGE_DIRECTION.IN && status < MESSAGE_STATUS.MESSAGE_SEEN &&
                                parent_chat === ((_a = this.chat) === null || _a === void 0 ? void 0 : _a._id);
                        }
                    });
                    if (!messages.length) {
                        return;
                    }
                }
                return this.rootStore.chatsStore.setMessagesRead(this.chat._id, typeof messages === 'object' ? messages.map(({ _id }) => _id) : true);
            })
        });
        Object.defineProperty(this, "sendTextMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (messageText, messageQuotedId) => __awaiter(this, void 0, void 0, function* () {
                if (!this.chat) {
                    throw new Error('No active chat!');
                }
                this.onSendMessage(this.rootStore.messages.sendTextMessage(this.chat._id, messageText, messageQuotedId, this.messengerSpecialTag));
            })
        });
        Object.defineProperty(this, "sendMediaMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (files, fileCaption, messageQuotedId) => __awaiter(this, void 0, void 0, function* () {
                if (!this.chat) {
                    throw new Error('No active chat!');
                }
                this.onSendMessage(this.rootStore.messages.sendMediaMessage(this.chat._id, files, fileCaption, messageQuotedId, this.messengerSpecialTag));
            })
        });
        Object.defineProperty(this, "sendContactsMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (contacts) => __awaiter(this, void 0, void 0, function* () {
                if (!this.chat) {
                    throw new Error('No active chat!');
                }
                this.onSendMessage(this.rootStore.messages.sendContactsMessage(this.chat._id, contacts, this.messengerSpecialTag));
            })
        });
        Object.defineProperty(this, "sendReactionMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (messageId, reaction) => __awaiter(this, void 0, void 0, function* () {
                if (!this.chat) {
                    throw new Error('No active chat!');
                }
                /** For now client will make sure there is always only one reaction */
                yield this.removeReactionMessage(messageId);
                return this.rootStore.messages.sendReactionMessage(messageId, { reaction });
            })
        });
        Object.defineProperty(this, "removeReactionMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (messageId) => __awaiter(this, void 0, void 0, function* () {
                if (!this.chat) {
                    throw new Error('No active chat!');
                }
                return this.rootStore.messages.removeReactionMessage(messageId);
            })
        });
        Object.defineProperty(this, "sendLocationMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (location) => __awaiter(this, void 0, void 0, function* () {
                if (!this.chat) {
                    throw new Error('No active chat!');
                }
                this.onSendMessage(this.rootStore.messages.sendLocationMessage(this.chat._id, location, this.messengerSpecialTag));
            })
        });
        Object.defineProperty(this, "loadChatAccess", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (chat) => __awaiter(this, void 0, void 0, function* () {
                const { getAgentChatAccess } = this.rootStore.agentsStore;
                const [manage, send, view] = yield Promise.all([
                    getAgentChatAccess(chat, ChatAccessType.manage),
                    getAgentChatAccess(chat, ChatAccessType.send),
                    getAgentChatAccess(chat, ChatAccessType.view),
                ]);
                runInAction(() => {
                    // "list" access is always true on the frontend because if chat already loaded to frontend then "list" access is granted
                    this.chatAccess = { list: true, manage, send, view };
                });
            })
        });
        Object.defineProperty(this, "changeShowTemplates", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.showTemplateMessages = !this.showTemplateMessages;
            }
        });
        Object.defineProperty(this, "unblockChat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                this.blockChatLoading = true;
                this.blockUserError = false;
                yield this.blockUnblockChat()
                    .catch(() => {
                    this.blockUserError = true;
                    setTimeout(() => runInAction(() => this.blockUserError = false));
                })
                    .finally(() => this.blockChatLoading = false);
            })
        });
        Object.defineProperty(this, "blockUnblockChat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                var _e, _f;
                const chat = this.chat;
                if (!chat) {
                    return;
                }
                const blockedChat = {
                    blocked: chat.blockedChat ? !((_e = chat.blockedChat) === null || _e === void 0 ? void 0 : _e.blocked) : true,
                    agent: ((_f = this.rootStore.loginStore.userDetails) === null || _f === void 0 ? void 0 : _f._id) || ''
                };
                const updated = yield this.rootStore.chatsStore.patchChat(chat._id, { blockedChat });
                runInAction(() => {
                    this.chat = updated;
                });
            })
        });
        Object.defineProperty(this, "setReplyMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (message) => {
                runInAction(() => {
                    this.activeReplyMessage = message;
                });
            }
        });
        Object.defineProperty(this, "clearReplyMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                runInAction(() => {
                    this.activeReplyMessage = null;
                });
            }
        });
        Object.defineProperty(this, "setInChatSearchQuery", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (query) => __awaiter(this, void 0, void 0, function* () {
                this.inChatSearchQuery = query;
                if (query && query.length >= 2) {
                    this.searchInChatMessages(false, query);
                }
            })
        });
        Object.defineProperty(this, "resetInChatSearch", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.inChatSearchQuery = '';
                this.inChatSearchResults = { messages: [], totalHits: 0 };
                this.inChatSearchLoading = MessagesSearchLoading.NONE;
            }
        });
        Object.defineProperty(this, "searchInChatMessages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: debounce((loadMore = false, query) => __awaiter(this, void 0, void 0, function* () {
                var _g;
                const params = {
                    limit: '50',
                    search: query,
                    chatId: (_g = this.chat) === null || _g === void 0 ? void 0 : _g._id,
                };
                if (loadMore) {
                    params.skip = `${this.inChatSearchResults.messages.length}`;
                }
                if (loadMore) {
                    this.inChatSearchLoading = MessagesSearchLoading.MORE;
                }
                else {
                    this.inChatSearchLoading = MessagesSearchLoading.ALL;
                }
                const results = yield this.rootStore.messages._getSearchResults(params);
                if (loadMore) {
                    this.inChatSearchResults.messages = [...this.inChatSearchResults.messages, ...results.messages]
                        .slice()
                        .sort((a, b) => b.date - a.date);
                }
                else {
                    this.inChatSearchResults = results;
                    this.inChatSearchResults.messages = this.inChatSearchResults.messages
                        .slice()
                        .sort((a, b) => b.date - a.date);
                }
                this.inChatSearchLoading = MessagesSearchLoading.NONE;
            }), 500)
        });
        Object.defineProperty(this, "setChatIsImportant", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (!this.chat) {
                    return;
                }
                const isImportant = this.rootStore.chatsStore.isChatImportant(this.chat._id);
                if (isImportant !== this.important) {
                    runInAction(() => {
                        this.important = isImportant;
                    });
                }
            }
        });
        Object.defineProperty(this, "getLinkedChats", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                var _h;
                const personId = (_h = this.chat) === null || _h === void 0 ? void 0 : _h.personId;
                runInAction(() => {
                    this.linkedChatsLoading = true;
                });
                if (personId) {
                    return yield getAxios()
                        .then((axios) => {
                        return axios.get(`${API_PATH.PERSONS}/${personId}`);
                    })
                        .then(({ data }) => {
                        runInAction(() => {
                            this.linkedChats.replace(data.chats);
                            this.linkedChatsLoading = false;
                        });
                    })
                        .catch((error) => {
                        console.error(error);
                        runInAction(() => {
                            this.linkedChats.replace([]);
                            this.linkedChatsLoading = false;
                        });
                    });
                }
                else {
                    runInAction(() => {
                        this.linkedChats.replace([]);
                        this.linkedChatsLoading = false;
                    });
                }
            })
        });
        Object.defineProperty(this, "linkChat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (chat) => __awaiter(this, void 0, void 0, function* () {
                var _j;
                if (!this.chat)
                    return;
                runInAction(() => {
                    this.linkedChatsLoading = true;
                });
                const personId = (_j = this.chat) === null || _j === void 0 ? void 0 : _j.personId;
                if (personId) {
                    const patchRequest = {
                        chats: [chat._id],
                    };
                    return yield getAxios()
                        .then((axios) => {
                        return axios.patch(`${API_PATH.PERSONS}/${personId}`, patchRequest);
                    })
                        .then(({ data }) => {
                        runInAction(() => {
                            this.linkedChats.push(chat);
                        });
                    })
                        .finally(() => {
                        runInAction(() => {
                            this.linkedChatsLoading = false;
                        });
                    });
                }
                else {
                    const createRequest = {
                        chats: [chat._id, this.chat._id],
                    };
                    return yield getAxios()
                        .then((axios) => {
                        return axios.post(`${API_PATH.PERSONS}`, createRequest);
                    })
                        .then(({ data }) => {
                        runInAction(() => {
                            this.linkedChats.replace([chat]);
                        });
                    })
                        .finally(() => {
                        runInAction(() => {
                            this.linkedChatsLoading = false;
                        });
                    });
                }
            })
        });
        Object.defineProperty(this, "isMessageLoaded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (messageId) => {
                return this.messages.some((message) => message._id === messageId);
            }
        });
        Object.defineProperty(this, "unlinkChat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (chatId) => __awaiter(this, void 0, void 0, function* () {
                var _k;
                const personId = (_k = this.chat) === null || _k === void 0 ? void 0 : _k.personId;
                if (personId) {
                    runInAction(() => {
                        this.linkedChatsLoading = true;
                    });
                    const postRequest = {
                        chats: [chatId],
                    };
                    return yield getAxios()
                        .then((axios) => {
                        return axios.post(`${API_PATH.PERSONS}/unlink`, postRequest);
                    })
                        .then(({ data }) => {
                        runInAction(() => {
                            this.linkedChats.replace(this.linkedChats.filter((c) => c._id !== chatId));
                        });
                    })
                        .finally(() => {
                        runInAction(() => {
                            this.linkedChatsLoading = false;
                        });
                    });
                }
            })
        });
        Object.defineProperty(this, "setSendLocationCoords", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (coords) => {
                this.sendLocationCoords = coords;
            }
        });
        /**
         * Replace message if _id or tmpSendId property matched.
         * Optionally adding to list and re-sorting if not found
         *
         * @todo Use proper type guard function to determine RegularMessage when it will be added
         *
         * @param message New message
         * @param upsert Add message to list if not found and re-sort by timestamp
         * @param allowDowngradeStatus Allow lower status of message than exists already
         */
        Object.defineProperty(this, "replaceMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (message, upsert = false, allowDowngradeStatus = false) => {
                let found = false;
                // @ts-expect-error
                if (message.tmpSendId) {
                    this.messages.replace(this.messages.map((msg) => {
                        if (msg.tmpSendId === message.tmpSendId
                            || msg._id === message._id) {
                            found = true;
                            if (!allowDowngradeStatus
                                && msg.status > message.status) {
                                return msg;
                            }
                            return message;
                        }
                        return msg;
                    }));
                }
                if (!found && upsert) {
                    this.messages.push(message);
                    this.messages.replace(this.messages.sort((a, b) => a.timestamp - b.timestamp));
                }
            }
        });
        Object.defineProperty(this, "checkActiveChatRequested", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { webViewInjectedData: WVData, defaultCountry } = this.rootStore, path = window.location.pathname;
                let id = '', channel, accountId, legacyContact;
                if (path.startsWith('/contact/')) {
                    const [, maybeChannel, maybeAccountId, maybeId] = path.substring(1).split('/');
                    if (maybeChannel
                        && maybeAccountId
                        && maybeId
                        && Object.values(MessagingChannel).includes(maybeChannel)) {
                        id = maybeId;
                        channel = maybeChannel;
                        accountId = maybeAccountId;
                    }
                    if (!maybeId && maybeChannel) {
                        legacyContact = maybeChannel;
                    }
                }
                if (!id && !!WVData.channel && !!WVData.account && !!WVData.contact) {
                    id = WVData.contact;
                    channel = WVData.channel;
                    accountId = WVData.account;
                }
                // Legacy method
                if (!id) {
                    let contact;
                    // Handle contact requested during web application first load inside mobile application
                    // TODO: Fix this in notifications/app
                    if (typeof WVData.contact === 'string') {
                        contact = WVData.contact;
                    }
                    else {
                        if (legacyContact) {
                            contact = legacyContact;
                        }
                        else {
                            contact = this.getContactPhoneFromUrlQuery();
                        }
                    }
                    if (contact) {
                        let phone;
                        // International phone number without "+" can start with "00" instead
                        if (contact.match(/^\+|^00/)) {
                            phone = parsePhoneNumber(contact);
                            // None of country codes starts with zero.
                            // So if it starts with single zero but not two - it's local number
                            // @link https://en.wikipedia.org/wiki/List_of_country_calling_codes
                        }
                        else if (contact.match(/^0/)) {
                            phone = parsePhoneNumber(contact, defaultCountry);
                        }
                        else {
                            phone = parsePhoneNumber(`+${contact}`);
                            // If pasing with added "+" failed, try as local
                            if (!phone || !phone.isValid()) {
                                const localGuess = parsePhoneNumber(contact, defaultCountry);
                                if (localGuess && localGuess.isValid()) {
                                    phone = localGuess;
                                }
                            }
                        }
                        if (phone) {
                            id = phone.format('E.164', { v2: true }).replace(/[^\d]/g, '');
                        }
                    }
                }
                if (id) {
                    this.setActiveChatByChannelId(id, channel, accountId);
                }
            }
        });
        Object.defineProperty(this, "toggleFilePreview", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (flag) => {
                this.filePreview = flag;
                if (flag === false) {
                    this.filesData = null;
                }
            }
        });
        Object.defineProperty(this, "setFilesData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (data) => {
                this.filesData = data;
            }
        });
        Object.defineProperty(this, "removeFile", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (fileUid) => {
                var _a;
                if (this.filesData) {
                    const files = (_a = this.filesData) === null || _a === void 0 ? void 0 : _a.files.filter(({ file }) => file.uid !== fileUid);
                    this.setFilesData(Object.assign(Object.assign({}, this.filesData), { files }));
                }
            }
        });
        Object.defineProperty(this, "addFiles", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (files) => {
                if (this.filesData) {
                    this.filesData.files.push(...files);
                }
                ;
            }
        });
        makeObservable(this);
        this.rootStore = rootStore;
        // Wait untill channels loaded
        if (this.rootStore.channels.accounts.length) {
            this.checkActiveChatRequested();
        }
        else {
            // Wait till loaded
            const disposeReaction = when(() => !!this.rootStore.channels.accounts.length, () => {
                this.checkActiveChatRequested();
                disposeReaction();
            });
        }
        // Handle notifications opened in mobile app
        // after web application already loaded and user logged in
        window.addEventListener('fcmMessageOpened', (event) => {
            if (event.detail && event.detail.contact) {
                if (event.detail.channel && event.detail.account) {
                    this.setActiveChatByChannelId(event.detail.contact, event.detail.channel, event.detail.account);
                    // Legacy method
                }
                else {
                    const number = parsePhoneNumber(event.detail.contact, this.rootStore.defaultCountry);
                    if (number) {
                        this.setActiveChatByChannelId(formatNumber(
                        // @ts-expect-error // v2
                        number, 'E.164', { v2: true }).replace(/[^\d]/g, ''));
                    }
                }
            }
        });
        // Handle mobile app sending location data after user allowed it
        window.addEventListener('getLocationCoords', (event) => {
            var _a, _b;
            if (((_a = event.detail) === null || _a === void 0 ? void 0 : _a.latitude) && ((_b = event.detail) === null || _b === void 0 ? void 0 : _b.longitude)) {
                const { latitude, longitude } = event.detail;
                this.setSendLocationCoords({ latitude, longitude });
            }
        });
        this.rootStore.events
            .addListener(EventName.chat_updated, (data) => this.onChatUpdatedEvent(data))
            .addListener(EventName.message_created, (data) => this.onMessageCreatedEvent(data))
            .addListener(EventName.message_updated, (data) => this.onMessageUpdatedEvent(data))
            .addListener(EventName.message_deleted, (data) => this.onMessageDeletedEvent(data))
            .addListener(LifecycleEvent.STREAM_OPEN, () => this.stopPolling())
            .addListener(LifecycleEvent.STREAM_FAILED, () => this.startPolling());
        /* Update important flag after user action */
        reaction(() => { var _a; return (_a = this.rootStore.loginStore.userDetails) === null || _a === void 0 ? void 0 : _a.favorites; }, () => this.setChatIsImportant());
        /* On chat change */
        reaction(() => this.chat, () => {
            this.setChatIsImportant();
            this.resetInChatSearch();
            this.clearReplyMessage();
        });
        /* Clear search chat data on module change (CRM is default) */
        reaction(() => this.rootStore.navigation.rightSidebarModule, (section) => {
            if (section === RightSidebarModules.CRM) {
                this.resetInChatSearch();
            }
        });
    }
    /**
     * Stop async routines.
     * Method should be called before object being destroyed or store setup again
     */
    destroy() {
        this.stopPolling();
    }
    /**
     * @deprecated This is temporary alias, for cases when somewhere left usage of old propery name. DO NOT USE IN THE NEW CODE!
     */
    get activeChat() {
        return this.chat
            ? Object.assign(Object.assign({}, this.chat), { messages: this.messages, sessionIsOver: this.sessionIsOver }) : null;
    }
    get hasPrevMessages() {
        // No messages - can't determine
        if (!this.messages.length) {
            return false;
        }
        // Need try to load anyway to get min ID
        if (this.messagesMinTime === null) {
            return true;
        }
        // No messages exists
        if (this.messagesMinTime === false) {
            return false;
        }
        // If first message time bigger than min time
        return this.messages[0].timestamp > this.messagesMinTime;
    }
    get hasNextMessages() {
        // No messages - can't determine
        if (!this.messages.length) {
            return false;
        }
        // Need try to load anyway to get max ID
        if (this.messagesMaxTime === null) {
            return true;
        }
        // No messages exists
        if (this.messagesMaxTime === false) {
            return false;
        }
        // If last message time less than max time
        return this.messages[this.messages.length - 1].timestamp < this.messagesMaxTime;
    }
    get isMessengerChannel() {
        var _a;
        return ((_a = this.chat) === null || _a === void 0 ? void 0 : _a.channelInfo.name) === MessagingChannel.messenger;
    }
    get sessionIsOver() {
        var _a;
        if (this.chat) {
            if (this.chat.channelInfo.name === MessagingChannel.website_chat) {
                return !!((_a = this.chat.channelInfo.metadata) === null || _a === void 0 ? void 0 : _a.offline);
            }
            let sessionIsOver = true;
            if (typeof this.chat.lastIncomingMessageTimestamp === 'number') {
                const now = Date.now();
                if (now - this.chat.lastIncomingMessageTimestamp > day) {
                    if (this.chat.channelInfo.name === MessagingChannel.messenger) {
                        sessionIsOver = false;
                        if (now - this.chat.lastIncomingMessageTimestamp > week) {
                            sessionIsOver = true;
                        }
                    }
                }
                else {
                    sessionIsOver = false;
                }
            }
            else if (!this.chat.lastIncomingMessageTimestamp) {
                sessionIsOver = true;
            }
            return sessionIsOver;
        }
        else {
            return false;
        }
    }
    /**
     * @todo This needs to be rewritten to proper generic implementation
     */
    get sessionIsOverMessage() {
        var _a;
        if (((_a = this.chat) === null || _a === void 0 ? void 0 : _a.channelInfo.name) === MessagingChannel.website_chat) {
            return 'sessionIsOverWebsite';
        }
        if (this.isMessengerChannel) {
            return 'sessionIsOverMessenger';
        }
        else {
            return 'sessionIsOver';
        }
    }
    get shouldAddMessengerMessageTag() {
        var _a;
        const now = Date.now();
        const sessionIsOlderThan24h = ((_a = this.chat) === null || _a === void 0 ? void 0 : _a.lastIncomingMessageTimestamp) &&
            now - this.chat.lastIncomingMessageTimestamp > day;
        return this.isMessengerChannel && sessionIsOlderThan24h && !this.sessionIsOver;
    }
    get messengerSpecialTag() {
        if (!this.shouldAddMessengerMessageTag)
            return undefined;
        return {
            messenger: {
                tag: Messenger.Message.Tag.HUMAN_AGENT,
            },
        };
    }
    onSendMessage(result) {
        const chatId = result[0].parent_chat;
        this.messages.push(result[0]);
        result[1]
            .then((message) => {
            if (this.chat && this.chat._id === chatId) {
                this.replaceMessage(message[0]);
            }
        })
            .catch((error) => {
            console.error(error);
        });
    }
    setTheme(chat) {
        var _a;
        const account = this.rootStore.channels.accounts.find(({ accountId }) => { var _a; return accountId === ((_a = chat.channelInfo) === null || _a === void 0 ? void 0 : _a.accountId); });
        const theme = this.rootStore.channels.themes[(account === null || account === void 0 ? void 0 : account.accountId) || chat.channel];
        if (theme) {
            this.theme = theme;
            setCSSVariable('--primary-color', isArray(theme.color) ? theme.color[1] : theme.color);
            if ((_a = theme.additionalData) === null || _a === void 0 ? void 0 : _a.invertLinkColor) {
                setCSSVariable('--link-color', '#1CC2FF'); // $secondary_blue
                setCSSVariable('--link-color-hover', shadeColor('#1CC2FF', -32));
            }
            else {
                setCSSVariable('--link-color', '#1a237e'); // $link_color
                setCSSVariable('--link-color-hover', '#3F51B5'); // $link_color_hover
            }
        }
    }
    getCached(chatId) {
        // Do not cache when no events stream
        if (null !== this.pollingInterval) {
            return;
        }
        return this.cache.find(({ chat }) => chat._id === chatId);
    }
    /**
     * @param id Provider/channel ID of the contact
     * @param channel Messaging channel
     * @param channelAccountId Messaging channel account id
     */
    getCachedByChannelId(id, channel, channelAccountId) {
        // Do not cache when no events stream
        if (null !== this.pollingInterval) {
            return;
        }
        return this.cache.find(({ chat }) => {
            return chat.channelInfo.id === id
                && chat.channelInfo.name === channel
                && chat.channelInfo.accountId === channelAccountId;
        });
    }
    /** Cache current chat and messages */
    cacheCurrent() {
        if (!this.chat) { // Nothing to cache
            return;
        }
        // Do not cache when no events stream
        if (null !== this.pollingInterval) {
            return;
        }
        // Already cached previously
        const index = this.cache.findIndex(({ chat }) => { var _a; return chat._id === ((_a = this.chat) === null || _a === void 0 ? void 0 : _a._id); });
        // If wasn't cached, and cache already maximal size, remove one oldest element (last one)
        if (-1 === index) {
            if (this.cache.length === cachedChatsSize) {
                this.cache.pop();
            }
            // Remove previously cached
        }
        else {
            this.cache.splice(index, 1);
        }
        // Add at the begin as most recent
        this.cache.unshift(
        // MobX toJS() function doesn't work well here for some reason
        // leaving messages objects in array reactive
        JSON.parse(JSON.stringify({
            chat: this.chat,
            messages: this.messages,
            minTime: this.messagesMinTime,
            maxTime: this.messagesMaxTime,
        })));
    }
    /**
     * Get chat contact phone number provided as "contact" query parameter
     *
     * @deprecated Exists for backward compatibility, consider to remove in v7+
     */
    getContactPhoneFromUrlQuery() {
        const url = window.location.href;
        if (url.includes('contact=')) {
            const match = url.match(/contact=(\+?\d+)/);
            let number;
            if (match) {
                number = match[1];
            }
            return number;
        }
    }
    onChatUpdatedEvent(chat) {
        if (this.chat && this.chat._id === chat._id) {
            this.setActiveChat(chat);
        }
        // Update chat cache if any
        const index = this.cache.findIndex((cache) => cache.chat._id === chat._id);
        if (-1 !== index) {
            this.cache[index].chat = chat;
        }
    }
    onMessageCreatedEvent(message) {
        if (this.chat && message.parent_chat === this.chat._id) {
            // Make sure we at the end of chat
            if (!this.hasNextMessages) {
                // Return to this approach if inserting from event will be buggy
                // but then need to make sure statuses delivered by events
                // not overwritten, because data loaded may be older than
                // delivered by events
                // this.loadMessages(true, true)
                //     .then(() => {
                //         this.checkIsSessionOver();
                //     });
                this.replaceMessage(message, true);
            }
            this.onCurrentChatMesageEventReceived();
        }
        // Delete message chat cache if any
        this.cache.replace(this.cache.filter((cache) => cache.chat._id !== message.parent_chat));
    }
    onMessageUpdatedEvent(updatedMsg) {
        if (this.chat && updatedMsg.parent_chat === this.chat._id && this.messages.length) {
            this.onCurrentChatMesageEventReceived();
            const index = this.messages.findIndex((msg) => msg._id === updatedMsg._id);
            if (-1 !== index) {
                this.messages[index] = updatedMsg;
            }
        }
        if (this.cache.length) {
            for (let i = 0; i < this.cache.length; i++) {
                if (this.cache[i].chat._id === updatedMsg.parent_chat) {
                    // @ts-expect-error // Nested arrays are observable, but it problematic to define type for all
                    this.cache[i].messages.replace(this.cache[i].messages.map((msg) => {
                        return msg._id === updatedMsg._id ? updatedMsg : msg;
                    }));
                    break;
                }
            }
        }
    }
    onMessageDeletedEvent(data) {
        if (this.messages.length) {
            this.onCurrentChatMesageEventReceived();
            this.messages.replace(this.messages.filter(({ _id }) => _id !== data._id));
        }
        if (this.cache.length) {
            // Checking all chats because have no parent chat ID
            for (let i = 0; i < this.cache.length; i++) {
                if (this.cache[i].messages.length) {
                    // @ts-expect-error // Nested arrays are observable, but it problematic to define type for all
                    this.cache[i].messages.replace(this.cache[i].messages.filter(({ _id }) => _id !== data._id));
                }
            }
        }
    }
    onCurrentChatMesageEventReceived() {
        this.lastMessagesRefreshedTime = Date.now();
        this.stopPolling();
    }
    startPolling() {
        // Force to load messages list on every switch
        this.cache.clear();
        if (null === this.pollingInterval) {
            this.refresh();
            this.pollingInterval = setInterval(() => {
                if (Date.now() - this.lastMessagesRefreshedTime >= pollingIntervalMsec) {
                    this.refresh();
                }
            }, pollingIntervalMsec);
        }
    }
    stopPolling() {
        if (null !== this.pollingInterval) {
            clearInterval(this.pollingInterval);
            this.pollingInterval = null;
            // Make sure all pulled for period between latest polling refresh and polling stopped
            this.refresh();
        }
    }
}
__decorate([
    observable
], ActiveChatStore.prototype, "chat", void 0);
__decorate([
    observable
], ActiveChatStore.prototype, "chatAccess", void 0);
__decorate([
    observable
], ActiveChatStore.prototype, "linkedChats", void 0);
__decorate([
    observable
], ActiveChatStore.prototype, "messages", void 0);
__decorate([
    observable
], ActiveChatStore.prototype, "messagesLoading", void 0);
__decorate([
    observable
], ActiveChatStore.prototype, "messagesMinTime", void 0);
__decorate([
    observable
], ActiveChatStore.prototype, "messagesMaxTime", void 0);
__decorate([
    observable
], ActiveChatStore.prototype, "chatLoading", void 0);
__decorate([
    observable
], ActiveChatStore.prototype, "linkedChatsLoading", void 0);
__decorate([
    observable
], ActiveChatStore.prototype, "messageId", void 0);
__decorate([
    observable
], ActiveChatStore.prototype, "cache", void 0);
__decorate([
    observable
], ActiveChatStore.prototype, "showTemplateMessages", void 0);
__decorate([
    observable
], ActiveChatStore.prototype, "blockChatLoading", void 0);
__decorate([
    observable
], ActiveChatStore.prototype, "blockUserError", void 0);
__decorate([
    observable
], ActiveChatStore.prototype, "filesData", void 0);
__decorate([
    observable
], ActiveChatStore.prototype, "filePreview", void 0);
__decorate([
    observable
], ActiveChatStore.prototype, "filePreviewLoading", void 0);
__decorate([
    observable
], ActiveChatStore.prototype, "theme", void 0);
__decorate([
    observable
], ActiveChatStore.prototype, "activeReplyMessage", void 0);
__decorate([
    observable
], ActiveChatStore.prototype, "important", void 0);
__decorate([
    observable
], ActiveChatStore.prototype, "sendLocationCoords", void 0);
__decorate([
    observable
], ActiveChatStore.prototype, "inChatSearchLoading", void 0);
__decorate([
    observable
], ActiveChatStore.prototype, "inChatSearchQuery", void 0);
__decorate([
    observable
], ActiveChatStore.prototype, "inChatSearchResults", void 0);
__decorate([
    observable
], ActiveChatStore.prototype, "isInChatSearchOpen", void 0);
__decorate([
    computed
], ActiveChatStore.prototype, "inChatSearchHasMore", null);
__decorate([
    computed
], ActiveChatStore.prototype, "indexedImageMessages", null);
__decorate([
    computed
], ActiveChatStore.prototype, "defaultTemplate", null);
__decorate([
    computed
], ActiveChatStore.prototype, "linkedChatsWithoutActiveChat", null);
__decorate([
    computed
], ActiveChatStore.prototype, "activeChat", null);
__decorate([
    computed
], ActiveChatStore.prototype, "hasPrevMessages", null);
__decorate([
    computed
], ActiveChatStore.prototype, "hasNextMessages", null);
__decorate([
    computed
], ActiveChatStore.prototype, "isMessengerChannel", null);
__decorate([
    computed
], ActiveChatStore.prototype, "sessionIsOver", null);
__decorate([
    computed
], ActiveChatStore.prototype, "sessionIsOverMessage", null);
__decorate([
    computed
], ActiveChatStore.prototype, "shouldAddMessengerMessageTag", null);
__decorate([
    computed
], ActiveChatStore.prototype, "messengerSpecialTag", null);
__decorate([
    action
], ActiveChatStore.prototype, "setActiveChatId", void 0);
__decorate([
    action
], ActiveChatStore.prototype, "removeActiveChat", void 0);
__decorate([
    action
], ActiveChatStore.prototype, "loadChatAccess", void 0);
__decorate([
    action
], ActiveChatStore.prototype, "onSendMessage", null);
__decorate([
    action
], ActiveChatStore.prototype, "changeShowTemplates", void 0);
__decorate([
    action
], ActiveChatStore.prototype, "unblockChat", void 0);
__decorate([
    action
], ActiveChatStore.prototype, "setReplyMessage", void 0);
__decorate([
    action
], ActiveChatStore.prototype, "clearReplyMessage", void 0);
__decorate([
    action
], ActiveChatStore.prototype, "setInChatSearchQuery", void 0);
__decorate([
    action
], ActiveChatStore.prototype, "resetInChatSearch", void 0);
__decorate([
    action
], ActiveChatStore.prototype, "searchInChatMessages", void 0);
__decorate([
    action
], ActiveChatStore.prototype, "linkChat", void 0);
__decorate([
    action
], ActiveChatStore.prototype, "unlinkChat", void 0);
__decorate([
    action
], ActiveChatStore.prototype, "setSendLocationCoords", void 0);
__decorate([
    action
], ActiveChatStore.prototype, "replaceMessage", void 0);
__decorate([
    action
], ActiveChatStore.prototype, "cacheCurrent", null);
__decorate([
    action
], ActiveChatStore.prototype, "checkActiveChatRequested", void 0);
__decorate([
    action
], ActiveChatStore.prototype, "onChatUpdatedEvent", null);
__decorate([
    action
], ActiveChatStore.prototype, "onMessageCreatedEvent", null);
__decorate([
    action
], ActiveChatStore.prototype, "onMessageUpdatedEvent", null);
__decorate([
    action
], ActiveChatStore.prototype, "onMessageDeletedEvent", null);
__decorate([
    action
], ActiveChatStore.prototype, "toggleFilePreview", void 0);
__decorate([
    action
], ActiveChatStore.prototype, "setFilesData", void 0);
__decorate([
    action
], ActiveChatStore.prototype, "removeFile", void 0);
__decorate([
    action
], ActiveChatStore.prototype, "addFiles", void 0);
export var MessagesLoading;
(function (MessagesLoading) {
    MessagesLoading["ALL"] = "ALL";
    MessagesLoading["PREV"] = "PREV";
    MessagesLoading["NEXT"] = "NEXT";
    MessagesLoading["NONE"] = "NONE";
})(MessagesLoading || (MessagesLoading = {}));
export default ActiveChatStore;

import React from 'react';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
export const loginSchemaJSON = {
    none: {
        titleKey: 'passwordSignInTitle',
        fields: [
            {
                name: 'email',
                type: 'text',
                label: 'email',
            },
            {
                name: 'password',
                type: 'text',
                label: 'password',
                component: 'Password',
                placeholder: '- - - -',
                extraInputProps: {
                    iconRender: (visible) => (visible ? React.createElement(EyeTwoTone, null) : React.createElement(EyeInvisibleOutlined, null)),
                },
            },
        ],
    },
    update_password: {
        titleKey: 'passwordExpiredTitle',
        fields: [
            {
                name: 'email',
                type: 'text',
                label: 'email',
            },
            {
                name: 'password',
                type: 'password',
                label: 'password',
                placeholder: '"- - - -"',
                extraInputProps: {
                    iconRender: (visible) => (visible ? React.createElement(EyeTwoTone, null) : React.createElement(EyeInvisibleOutlined, null)),
                },
            },
            {
                name: 'newPassword',
                type: 'password',
                label: 'newPassword',
                placeholder: '"- - - -"',
                extraInputProps: {
                    iconRender: (visible) => (visible ? React.createElement(EyeTwoTone, null) : React.createElement(EyeInvisibleOutlined, null)),
                },
            },
        ],
    },
    mfa: {
        titleKey: 'codeSignInTitle',
        fields: [
            {
                name: 'code',
                type: 'text',
                label: 'code',
            },
        ],
    },
};

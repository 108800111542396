var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component } from 'react';
import { Image } from 'antd';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import { withStore } from '../../../../store/rootStore';
import { getFilePreviewUrl, getFileUrl } from '../../../../store/FilesStore';
import downloadIcon from '../../../../assets/icons/download/newDownloadIcon.png';
import imagePlaceholderIcon from '../../../../assets/icons/imagePreview/chatImageIcon.svg';
import { ReactComponent as PlaceholderSVG } from '../../../../assets/images/placeholders/image_placeholder_300.svg';
import MessageMarkdown from '../../../Base/Utils/MessageMarkdown/MessageMarkdown';
import './ImageMessage.scss';
const loadRetryTInit = 2000;
const loadRetryTMax = 8000;
class ImageMessage extends Component {
    constructor(props) {
        super(props);
        // Do not putting to state to avoid component re-rendering
        Object.defineProperty(this, "retryT", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: loadRetryTInit
        });
        Object.defineProperty(this, "wrapperRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "componentDidMount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.wrapperRef.current) {
                    const maskInfo = this.wrapperRef.current.querySelector('.ant-image-mask > .ant-image-mask-info');
                    if (maskInfo) {
                        const { t } = this.props;
                        maskInfo.innerText = t('preview');
                    }
                }
            }
        });
        Object.defineProperty(this, "updateDownloadUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (media) => {
                const { url, fileId } = media;
                if (url !== this.state.url || fileId !== this.state.fileId) {
                    this.setState({
                        url,
                        fileId,
                    });
                }
            }
        });
        Object.defineProperty(this, "onOpenImage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                const { imageMessages, media } = this.props;
                const index = ((_a = imageMessages.find((m) => m.fileId === media.fileId)) === null || _a === void 0 ? void 0 : _a.index) || 0;
                this.setState({
                    visible: true,
                    galleryIndex: index,
                });
            }
        });
        Object.defineProperty(this, "saveImage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                const { shareFile } = this.props.store.files;
                const { url, fileId } = this.state;
                let u = url;
                if (window.ReactNativeWebView) {
                    if (fileId) {
                        u = yield shareFile(fileId);
                    }
                    window.ReactNativeWebView.postMessage(JSON.stringify({
                        event: 'saveImage',
                        url: u,
                    }));
                }
                else {
                    if (fileId) {
                        u = getFileUrl(fileId, true);
                    }
                    window.open(u, '_blank');
                }
            })
        });
        Object.defineProperty(this, "render", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { message, imageMessages, customColor, media, showCaption } = this.props;
                const { previewUrl: imgSrc, visible, galleryIndex } = this.state;
                const { caption, fileId } = media;
                return (React.createElement("div", { ref: this.wrapperRef, className: "image-message-container" },
                    React.createElement(Image, { preview: { visible: false }, id: `image_${fileId}`, className: "image-message-image", placeholder: React.createElement(ImagePlaceholder, null), fallback: imagePlaceholderIcon, onClick: this.onOpenImage, key: imgSrc, src: imgSrc, onError: () => this.onImageError(), loading: "lazy" }),
                    (caption && showCaption) ? (React.createElement("p", { dir: "auto", className: classnames('image-message-filename', { 'image-white-text': !message.incoming }), style: { color: customColor } },
                        React.createElement(MessageMarkdown, { text: caption }))) : null,
                    React.createElement("div", { style: { display: 'none' } },
                        React.createElement(Image.PreviewGroup, { preview: {
                                visible,
                                onVisibleChange: (visible) => this.setState({ visible }),
                                current: galleryIndex,
                                countRender: (current, total) => {
                                    if (visible && current > 0) {
                                        this.updateDownloadUrl(imageMessages[current - 1]);
                                    }
                                    return `${current} / ${total}`;
                                },
                            } }, imageMessages.map(({ fileId, url, filename, index }) => (React.createElement(Image, { id: `${fileId}_${filename}_${message._id}_${index}`, key: `${fileId}_${filename}_${message._id}_${index}`, className: "image-message-image", placeholder: React.createElement(ImagePlaceholder, null), fallback: imagePlaceholderIcon, src: fileId ? getFileUrl(fileId) : url, loading: "lazy" })))))));
            }
        });
        Object.defineProperty(this, "onImageError", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.retryT >= loadRetryTMax)
                    return; // Too much attempts
                setTimeout(() => {
                    this.setState((state) => {
                        return {
                            previewUrl: this.urlAppendTimestamp(state.previewUrl),
                        };
                    });
                }, this.retryT);
                this.retryT = this.retryT * 2; // Double timeout on each attempt
            }
        });
        // Inject timestamp to force browser try load again
        Object.defineProperty(this, "urlAppendTimestamp", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (url) => {
                let t = Date.now();
                if (url.includes('?')) {
                    return url.replace(/\?(t\d+&)?/, '?t' + t + '&');
                }
                return url + '?t' + t;
            }
        });
        const { url, fileId } = this.props.media;
        this.state = {
            visible: false,
            previewUrl: fileId ? getFilePreviewUrl(fileId) : url || '',
            url,
            fileId: fileId,
            galleryIndex: 1,
        };
        this.wrapperRef = React.createRef();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.visible) {
            setTimeout(() => {
                const downloadNode = document.querySelector('.downloadImageButton');
                const headersList = document.querySelector('.ant-image-preview-operations');
                if (!downloadNode) {
                    const img = document.createElement('img');
                    img.src = downloadIcon;
                    img.classList.add('downloadImageButton');
                    const li = document.createElement('li');
                    li.appendChild(img);
                    li.classList.add('downloadImageButtonWrapper');
                    li.addEventListener('click', () => this.saveImage());
                    if (headersList) {
                        headersList.appendChild(li);
                    }
                }
                /* guarantee DOM repaint first */
            }, 0);
        }
    }
}
function ImagePlaceholder() {
    return (React.createElement("div", { className: "message-image-placeholder" },
        React.createElement(PlaceholderSVG, null)));
}
export default withTranslation()(withStore(ImageMessage));

import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { Trans, withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { withStore } from '../../store/rootStore';
import { USER_STATES } from '../../constants';
import Logo from '../../assets/logos/texterLogos/texter-logo-square2-192x192.png';
import LanguageSelect from '../LeftDrawer/LanguageSelect';
import LoginMethod from './components/LoginMethod';
import { getLoginMethodFromChallenge } from './helpers';
import styles from './Login.module.scss';
class Login extends Component {
    constructor(props) {
        super(props);
        Object.defineProperty(this, "checkIfUserIsLoggedInIntervalId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "setErrorMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (errorCode) => {
                switch (errorCode) {
                    case 'auth/user-not-found':
                        this.setState({ errorCode: 'emailError' });
                        break;
                    case 'auth/wrong-password':
                        this.setState({ errorCode: 'passwordError' });
                        break;
                    case 'auth/user-disabled':
                        this.setState({ errorCode: 'disabledError' });
                        break;
                    case 'auth/wrong-password':
                    case 'auth/too-many-requests':
                    case 'auth/invalid-email':
                        this.setState({ errorCode });
                    default:
                        this.setState({ errorCode: 'loginConnectionError' });
                }
                setTimeout(() => this.setState({ errorCode: '' }), 5000);
            }
        });
        Object.defineProperty(this, "handleChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (field, value) => {
                this.setState((prevState) => ({
                    loginBody: Object.assign(Object.assign({}, prevState.loginBody), { [field]: value }),
                }));
            }
        });
        Object.defineProperty(this, "handleSubmit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                e.preventDefault();
                this.setState({ showValidationMessage: false });
                const { challenge, userState, logIn, mfa } = this.props.store.loginStore;
                const { loginBody } = this.state;
                const loginMethod = getLoginMethodFromChallenge(challenge);
                if (mfa) {
                    loginBody.mfa = mfa;
                }
                logIn(loginMethod, loginBody).then(() => {
                    if (userState === USER_STATES.LOGGED_IN) {
                        this.props.history.push('/');
                    }
                });
            }
        });
        Object.defineProperty(this, "handleResetPassword", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.state.loginBody.email) {
                    this.setState({ errorCode: '' });
                    this.props.store
                        .resetPassword(this.state.loginBody.email)
                        .then(() => {
                        this.setState({ checkEmail: true });
                    })
                        .catch((e) => {
                        if (e.error.message === 'EMAIL_NOT_FOUND') {
                            this.setState({ errorCode: 'emailNotFound' });
                        }
                        else if (e.error.message === 'RESET_PASSWORD_EXCEED_LIMIT') {
                            this.setState({ errorCode: 'exceededLimit' });
                        }
                        else if (e.error.message === 'INVALID_EMAIL') {
                            this.setState({ errorCode: 'invalidEmail' });
                        }
                        else {
                            this.setState({ errorCode: 'passResetError' });
                        }
                    });
                }
                else {
                    this.setState({ errorCode: 'enterYourEmail' });
                }
                setTimeout(() => this.setState({ checkEmail: null, errorCode: '' }), 3000);
            }
        });
        Object.defineProperty(this, "showValidationMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({ showValidationMessage: true });
            }
        });
        this.state = {
            errorCode: '',
            checkEmail: null,
            showValidationMessage: false,
            disabled: false,
            partnerTheme: props.store.getPartnerTheme(),
            loginBody: {},
        };
    }
    render() {
        const { store } = this.props;
        const { partnerTheme, loginBody } = this.state;
        const { loading, errorMessage, challenge, showPasswordReset, userState } = store.loginStore;
        if (userState === USER_STATES.LOGGED_IN) {
            return React.createElement(Redirect, { to: "/" });
        }
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.content },
                React.createElement("div", { className: styles.topBar },
                    React.createElement("div", { className: styles.topBarContent },
                        React.createElement("img", { className: classnames(styles.logo, {
                                [styles.custom]: !!partnerTheme,
                            }), src: partnerTheme ? partnerTheme.icon : Logo }),
                        React.createElement("div", { className: styles.centralTextBlock },
                            React.createElement("div", { className: styles.mainText }, partnerTheme ? (React.createElement(Trans, { i18nKey: "loginTo", values: { name: partnerTheme.name } })) : (React.createElement(Trans, { i18nKey: "loginToTexterchat" })))),
                        React.createElement(LanguageSelect, { className: styles.languageSelect }))),
                React.createElement(LoginMethod, { challenge: challenge, data: loginBody, onSubmit: this.handleSubmit, onChange: this.handleChange, loading: loading }),
                React.createElement("p", { className: styles.termsAndPrivacy },
                    React.createElement(Trans, { i18nKey: "termsAndPrivacy", components: {
                            1: React.createElement("a", { href: "https://texterchat.com/terms", target: "_blank" }),
                            2: React.createElement("a", { href: "https://texterchat.com/privacy", target: "_blank" }),
                        } })),
                React.createElement("div", { className: styles.messagesBlock },
                    errorMessage ? (React.createElement("p", { className: classnames(styles.notificationText, styles.loginError) }, errorMessage)) : null,
                    this.state.checkEmail ? (React.createElement("p", { className: classnames(styles.notificationText, styles.loginEmailCheck) },
                        React.createElement(Trans, { i18nKey: "checkEmail" }))) : null),
                showPasswordReset && (React.createElement("a", { className: styles.linkButton, onClick: () => this.handleResetPassword() },
                    React.createElement(Trans, { i18nKey: "resetPassword" }))),
                window.ReactNativeWebView && (React.createElement("a", { className: styles.linkButton, onClick: () => {
                        window.ReactNativeWebView.postMessage(JSON.stringify({
                            event: 'goToProjectSelect',
                        }));
                    } },
                    React.createElement(Trans, { i18nKey: "changeProject" }))))));
    }
}
export default withTranslation()(withStore(observer(Login)));
